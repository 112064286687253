<template>
  <base-section
    id="upcoming-events"
    class="px-5"
  >
    <base-section-heading
      title="EXPO"
    >
    </base-section-heading>
      <v-row
        class="justify-center"
      >
        <v-col
          v-for="(info, n) in information"
          :key="n"
          cols="12"
          md="3"
          sm="6"
        >
          <v-card
            class="mx-auto black--text"
            width="400"
          >
            <v-img
              :src="info.src"
              :height="$vuetify.breakpoint.mdAndUp ? 200 : 200"
            ></v-img>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>TRADE SHOW</td>
                    <td>{{ info.show }}</td>
                  </tr>
                  <tr>
                    <td>DATE</td>
                    <td>{{ info.date }}</td>
                  </tr>
                  <tr>
                    <td>VENUE</td>
                    <td>{{ info.venue }}</td>
                  </tr>
                  <tr>
                    <td>BOOTH NUMBER</td>
                    <td>{{ info.booth }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

          </v-card>
        </v-col>
      </v-row>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionUpcomingEvents',
    data () {
      return {
        information: [
          {
            show: 'IMEC EXPO 2022',
            date: '19-21 July 2022',
            venue: 'Kuala Lumpur Convention Center (KLCC)',
            booth: 'East Wing E13',
            src: require('@/assets/IMEC-EXPO-logo.jpg'),
          },
          {
            show: 'Florida International Medical Expo (FIME) 2022',
            date: '27-29 July 2022',
            venue: 'Miami Beach Convention Center',
            booth: 'C69',
            src: require('@/assets/fime.jpg'),
          },
          {
            show: '10th International Rubber Glove Conference and Exhibition (IRGCE) 2022',
            date: '6-9 September 2022',
            venue: 'Kuala Lumpur Convention Center (KLCC)',
            booth: 'Hall 5 W8',
            src: require('@/assets/irgce2.jpg'),
          },
        ],
      }
    },
  }
</script>
<style type="text/css">
.v-card__text {
  letter-spacing: 0.0rem;
}
</style>
